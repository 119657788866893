import React from "react";
import { Link } from "react-router-dom";
import classes from "./Terms.module.css";

function Terms() {
  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <h1>Terms and Conditions of Use</h1>
        <p>
          Welcome to Kenjap Motors LTD. ("Kenjap Motors," "we," "us," or "our").
          By accessing or using our website, you agree to comply with and be
          bound by these Terms of Use. Please read these terms carefully. If you
          do not agree with these terms, you should not use our website.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the Kenjap Motors website, you agree to these Terms of Use
          and our Privacy Policy. We may update these Terms from time to time
          without notice, and it is your responsibility to review them
          regularly.
        </p>
        <h2>2. Eligibility</h2>
        <p>
          You must be at least 18 years old to use our website and services. By
          using our website, you represent and warrant that you meet this age
          requirement.
        </p>
        <h2>3. User Accounts</h2>
        <p>
          To access certain features of our website, you may be required to
          create an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your password and agree not to disclose
          it to any third party. You are responsible for all activities that
          occur under your account.
        </p>
        <h2>4. Buying and Selling Vehicles</h2>
        <ul className={classes.buysell}>
          <li>
            <strong>Listings:</strong> Users can browse listings for new, used,
            and imported vehicles. Kenjap Motors LTD reserves the right to
            approve, edit, or remove any listing at our discretion.
          </li>
          <li>
            <strong>Purchases:</strong> All sales are final. Buyers should
            thoroughly inspect vehicles and perform due diligence before making
            a purchase.
          </li>
          <li>
            <strong>Selling:</strong> Users can submit their vehicles for sale
            through our website. By submitting a vehicle, you warrant that you
            are the rightful owner and have the authority to sell the vehicle.
          </li>
        </ul>
        <h2>5. Pricing and Payments</h2>
        <p>
          All prices are listed in Kenyan Shillings (Kshs). Payments for
          vehicles or services must be made through the methods specified on our
          website. Kenjap Motors LTD is not responsible for any fees or charges
          your bank or payment provider may apply.
        </p>
        <h2>6. Intellectual Property</h2>
        <p>
          All content on the Kenjap Motors LTD website, including text,
          graphics, logos, and images, is the property of Kenjap Motors LTD or
          its content suppliers and is protected by international copyright
          laws. You agree not to reproduce, distribute, or create derivative
          works based on our content without our express written permission.
        </p>
        <h2>7. Prohibited Uses</h2>
        <p>
          You agree not to use the Kenjap Motors LTD website for any unlawful
          purpose or in any way that could damage, disable, overburden, or
          impair the website. This includes, but is not limited to, engaging in
          any fraudulent activities, infringing on intellectual property rights,
          or submitting false information.
        </p>
        <h2>8. Disclaimers and Limitation of Liability</h2>
        <p>
          Kenjap Motors LTD provides the website and its services "as is" and
          "as available." We make no representations or warranties of any kind,
          express or implied, regarding the operation of the website or the
          information, content, or products included on the website. To the full
          extent permissible by law, Kenjap Motors LTD disclaims all warranties,
          express or implied. Kenjap Motors LTD will not be liable for any
          damages of any kind arising from the use of our website or services.
        </p>
        <h2>9. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Kenjap Motors LTD, its
          affiliates, and their respective directors, officers, employees, and
          agents from and against any claims, liabilities, damages, losses, and
          expenses arising out of or in any way connected with your use of our
          website or services.
        </p>
        <h2>10. Governing Law</h2>
        <p>
          These Terms of Use are governed by and construed in accordance with
          the laws of Kenya. You agree to submit to the exclusive jurisdiction
          of the courts located in Kenya for any disputes arising out of or
          relating to these Terms or your use of the website.
        </p>
        <h2>11. Changes to Terms of Use</h2>
        <p>
          Kenjap Motors LTD reserves the right to modify these Terms of Use at
          any time. Any changes will be effective immediately upon posting on
          the website. Your continued use of the website following the posting
          of changes constitutes your acceptance of such change
          <Link to="/register">.</Link>
        </p>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms of Use, please
          contact us at:
          <br />
          Kenjap Motors LTD,
          <br />
          West Road, Adjacent to The Ole-Ken Hotel,
          <br />
          Next to Mt. Kenya University, Nakuru Campus,
          <br />
          Phone: +254 722 774 846,
          <br />
          Email: info@kenjapmotors.com.
        </p>
        <p>
          Thank you for using Kenjap Motors LTD website. We hope you have a
          positive experience on our website.
        </p>
      </div>
    </div>
  );
}

export default Terms;
